import React, {
  memo, useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import api from '../../../../api/req';
import AppContext from '../../../../providers/authProvider';
import CLEditor from './checklisteditor';
import ChecklistShower from './shower';
import Loader from '../../../../components/Styled/Misc/loader';

function Editor({ peopleId }) {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [currentChecklist, setCurrentChecklist] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const load = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get('/api/checklists/', auth, { people: peopleId });
        if (!r.ok) throw new Error(r.statusText);
        return r.json();
      };
      setErr(null);
      setLoading(true);
      loader()
        .then((d) => setCurrentChecklist(d.length ? d[0].id : null))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, peopleId],
  );
  useEffect(() => load(), [load]);
  return (
    <div className="position-relative">
      {err && (
      <Alert dismissible onClose={() => setErr(null)} variant="danger">
        <Alert.Heading>
          {err}
        </Alert.Heading>
      </Alert>
      )}
      {loading && (
        <Loader relative />
      )}
      {!currentChecklist && !editMode && (
      <p>
        <span>Candidate hasn&apos;t checklist. You can</span>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span role="button" tabIndex="-1" className="text-primary mx-2" onClick={() => setEditMode(true)}>create</span>
        <span>new</span>
      </p>
      )}
      {currentChecklist && !editMode && (
      <div className="mb-1">
        <ChecklistShower
          id={currentChecklist}
          onSetEditMode={() => setEditMode(true)}
        />
      </div>

      )}
      {editMode && (
      <CLEditor
        id={currentChecklist ? String(currentChecklist) : 'create'}
        peopleId={peopleId}
        onSave={() => {
          load();
          setEditMode(false);
        }}
        onDelete={() => {
          load();
          setEditMode(false);
        }}
      />
      )}
    </div>

  );
}
Editor.propTypes = {
  peopleId: PropTypes.number.isRequired,
};

export default memo(Editor);
