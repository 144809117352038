import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Badge, Button, Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk, faBarcode, faBuilding,
  faCalendar,
  faEarthEurope, faEnvelope,
  faPersonCircleQuestion,
  faPersonWalkingLuggage, faPhone,
  faQuestion, faRefresh, faShip, faTimes, faUser, faVenusMars,
} from '@fortawesome/free-solid-svg-icons';
import DateShow from '../../../components/Controls/dates/dateShow';

function PeopleItem({
  row, closed, showClosedInfo, onClose, onReopen,
}) {
  return (
    <Card className="h-100 ">
      <Card.Body>
        <Row className="border-bottom pb-1 mb-1">
          <Col xxl={4} xl={5} className="d-none d-xl-block">
            <Link to={`/candidates/${row.id}/`}>
              <img src={row.photo} alt={`${row.name} ${row.family_name}`} className="w-100 rounded" />
            </Link>
          </Col>
          <Col xxl={8} xl={7} lg={12}>
            <Card.Title as={Link} className="mt-2 h5 text-decoration-none" to={`/candidates/${row.id}/`}>
              {`${row.name} ${row.family_name}`}
            </Card.Title>
            <div className="d-flex justify-content-end position-absolute top-0 end-0 w-50">
              {row.labels.map((l) => (
                <Badge
                  key={l.id}
                  style={{ backgroundColor: l.bg_color, color: l.color }}
                  bg="none"
                  className="border"
                >
                  {l.name}
                </Badge>
              ))}
            </div>
            {showClosedInfo && (
            <div className="d-flex justify-content-end">
              {closed && <span>CLOSED</span>}
              {closed ? (
                <Button variant="outline-secondary" onClick={onReopen} size="sm">
                  <FontAwesomeIcon icon={faRefresh} className="me-2" />
                  Reopen
                </Button>
              ) : (
                <Button variant="outline-danger" onClick={onClose} size="sm">
                  <FontAwesomeIcon icon={faTimes} className="me-2" />
                  Close
                </Button>
              )}
            </div>
            )}
            <ul className="list-unstyled">
              <li>
                <FontAwesomeIcon icon={faBarcode} className="me-1 text-muted" size="sm" />
                <span className="fw-bold text-primary">{row.id}</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <a href={`tel:${row.phone}`} className="text-decoration-none">{row.phone}</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="me-1 text-muted" size="sm" />
                <a href={`mailto:${row.email}`} className="text-decoration-none">{row.email}</a>
              </li>
              <li>
                {row.current_job && (
                <>
                  <FontAwesomeIcon icon={faPersonWalkingLuggage} className="me-1 text-muted" size="sm" />
                  <span>{row.current_job.job.repr}</span>
                </>
                )}
              </li>

            </ul>
          </Col>
        </Row>

        <ul className="list-unstyled">
          <li>
            <FontAwesomeIcon icon={faEarthEurope} className="me-1 text-muted" size="sm" />
            <span>{row.citizenship_name}</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBuilding} className="me-1 text-muted" size="sm" />
            {row.positions.length ? row.positions.map((p) => (
              <span key={p.id} style={{ color: p.color }} className="border rounded px-2 me-1">
                {p.repr}
              </span>
            )) : (
              <span className="text-danger">
                No positions
              </span>
            )}
          </li>
          <li>
            {row.current_status && (
            <>
              <FontAwesomeIcon icon={faPersonCircleQuestion} className="me-1 text-muted" size="sm" />
              <span style={{ color: row.current_status.workflow.color }}>
                {row.current_status.workflow.repr}
              </span>
            </>
            )}
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} className="me-1 text-muted" size="sm" />
            <span>{row.author ? row.author.repr : 'self'}</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faCalendar} className="me-1 text-muted" size="sm" />
            <DateShow value={row.birth_date} />
          </li>
          <li>
            <FontAwesomeIcon icon={faVenusMars} className="me-1 text-muted" size="sm" />
            <span>
              {' '}
              {row.gender === 'M' ? 'Male' : 'Female'}
            </span>
          </li>
          <li>
            {!!row.preferences.length && (
            <>
              <FontAwesomeIcon icon={faAsterisk} className="me-1 text-muted" size="sm" />
              {row.preferences.map((pp) => (
                <span key={pp.id}>{pp.company_name}</span>
              ))}
            </>
            )}
          </li>
          <li>
            {!!row.landing_date && (
            <>
              <FontAwesomeIcon icon={faShip} className="me-1 text-muted" size="sm" />
              <DateShow value={row.landing_date} />
              -
                {row.end_date ? (<DateShow value={row.end_date} />) : (
                  <FontAwesomeIcon icon={faQuestion} className="me-1 text-muted" size="sm" />
                )}
            </>
            )}
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}

PeopleItem.propTypes = {
  row: PropTypes.shape({}).isRequired,
  showClosedInfo: PropTypes.bool,
  closed: PropTypes.bool,
  onReopen: PropTypes.func,
  onClose: PropTypes.func,
};

PeopleItem.defaultProps = {
  showClosedInfo: false,
  closed: false,
  onReopen: null,
  onClose: null,
};

export default PeopleItem;
