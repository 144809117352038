/* eslint-disable react/jsx-no-bind */
import React, {
  forwardRef, useCallback, useMemo,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import PropTypes from 'prop-types';
import { Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';
import DatePickerInput from './datePickerInput';

const StyledButtonContainer = styled.div`
  max-width: 23rem;
  row-gap: .75rem;
`;

const StyledInputGroup = styled(InputGroup)`
  & .react-datepicker-wrapper {
    flex: 1 1 auto;
  }
`;

const DtPicker = forwardRef(({
  id, value, onChange, disabled, readOnly, onFocus, onBlur,
  errors, variants, errorAsTooltip, size, prepend,
}, ref) => {
  const vValue = useMemo(
    () => {
      if (!variants) return null;
      return variants.filter((v) => v.value === value).reduce((R, v) => v.display_name, null);
    },
    [value, variants],
  );
  const cDate = useMemo(
    () => {
      if (!value) return null;
      if (!Date.parse(value)) return null;
      const d = new Date(value);
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      return d;
    },
    [value],
  );

  const onDateChange = useCallback(
    (v, e) => {
      v.setMinutes(v.getMinutes() - v.getTimezoneOffset());
      onChange(e, v.toISOString().substring(0, 10));
    },
    [onChange],
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function CContainer({ className, children }) {
    return (
      <CalendarContainer className={className}>
        <StyledButtonContainer className="bg-white d-flex justify-content-between flex-wrap p-2 border-bottom">
          {(variants || []).map((v) => (
            <Button
              key={v.value}
              size="sm"
              variant="outline-secondary"
              active={v.value === value}
              onClick={(e) => onChange(e, v.value)}
              className="text-nowrap rounded-pill"
            >
              {v.display_name}
            </Button>
          ))}

        </StyledButtonContainer>
        <div className="position-relative">
          {children}
        </div>
      </CalendarContainer>
    );
  }

  CContainer.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };
  const errored = errors && errors.length;

  const onFocusHanlder = useCallback(
    (e) => {
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const onBlurHanlder = useCallback(
    (e) => {
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  return (
    <StyledInputGroup className={classNames('flex-nowrap', { 'is-invalid': errored })} size={size}>
      {prepend}
      <DatePicker
        id={id}
        ref={ref}
        selected={cDate}
        onChange={onDateChange}
        onFocus={onFocusHanlder}
        onBlur={onBlurHanlder}
        className="w-100"
        dateFormat="dd.MM.yyyy"
        locale="en"
        disabled={disabled || readOnly}
        showYearDropdown
        customInput={(
          <DatePickerInput
            errors={errors}
            errorAsTooltip={errorAsTooltip}
            vValue={vValue}
            onFocus={onFocus}
            onBlur={onBlur}
            className="flex-fill"
            size={size}
          />
      )}
        calendarContainer={CContainer}
      />
    </StyledInputGroup>
  );
});

DtPicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  variants: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })),
  errorAsTooltip: PropTypes.bool,
  prepend: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

DtPicker.defaultProps = {
  id: '',
  value: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  readOnly: false,
  errors: null,
  variants: null,
  errorAsTooltip: false,
  size: 'sm',
  prepend: '',
};

export default DtPicker;
