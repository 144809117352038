import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button, Col, Container, Form, InputGroup, Modal, Nav, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faBarcode,
  faBriefcase,
  faCheck,
  faIdCard,
  faLanguage,
  faLocationDot,
  faMessage,
  faSave,
  faShip,
  faTriangleExclamation,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
import {
  FileUpload,
  FilePreview,
} from '../../../components/Controls';
import { ContentLabel } from '../../../components/Styled/Misc';
import api from '../../../api/req';
import { debounce } from '../../../api/utils';
import { useDict } from '../../BaseLister/hook';
import { EditorControls, editorHooks } from '../../BasicEditor/editorControls';
import AppContext from '../../../providers/authProvider';
import Languages from './languages';
import Preferences from './preferences';
import AddContacts from './addContacts';
import WorkExpiriences from './workExps';
import Educations from './educs';
import { DeleteButton } from '../../../components/Styled/Buttons';

const ImgFoto = styled.div.attrs(({ src }) => ({
  style: {
    backgroundImage: `url("${src}")`,
    color: 'var(--primary)',
  },
}))`
    width: min(200px, 100%);
    height: 200px;
    align-self: center;
    background-position: center center;
    background-size: cover;
`;

export const InfoSpan = styled.span`
    color: #00007d;
`;

function PeoplesEditor({
  data, fields, fieldErrors, onChange, isNew, onSave, onDelete, readOnly,
}) {
  // eslint-disable-next-line no-unused-vars
  const { auth, currentUser } = useContext(AppContext);

  const [phoneChecked, setPhoneChecked] = useState(false);
  const [phonePassed, setPhonePassed] = useState(true);
  const [emailChecked, setEmailChecked] = useState(false);
  const [emailPassed, setEmailPassed] = useState(true);
  const [nameChecked, setNameChecked] = useState(false);
  const [namePassed, setNamePassed] = useState(true);

  useEffect(() => {
    setPhoneChecked(!isNew);
    setEmailChecked(!isNew);
    setNameChecked(!isNew);
  }, [isNew]);

  const countries = useDict({ url: '/api/cat/country/' });
  const contactInfoTypes = useDict({ url: '/api/cat/contact_info_type/' });
  const positions = useDict({ url: '/api/cat/position/' });
  const languages = useDict({ url: '/api/cat/language/' });
  const companies = useDict({ url: '/api/cat/companies/' });
  const users = useDict({ url: '/api/core/user/' });

  const checkPhone = useCallback(
    async (phone) => {
      const check = {
        phone,
        ...isNew ? {} : { id: data.id },
      };
      const r = await api.get('/api/cat/peoples/check_phone/', auth, check);
      if (r.ok) {
        const d = await r.json();
        setPhoneChecked(true);
        setPhonePassed(d.count === 0);
      }
    },
    [auth, data.id, isNew],
  );

  const checkEMail = useCallback(
    async (email) => {
      const check = {
        email,
        ...isNew ? {} : { id: data.id },
      };
      const r = await api.get('/api/cat/peoples/check_email/', auth, check);
      if (r.ok) {
        const d = await r.json();
        setEmailChecked(true);
        setEmailPassed(d.count === 0);
      }
    },
    [auth, data.id, isNew],
  );
  const checkName = useCallback(
    async ({
      // eslint-disable-next-line camelcase
      name, family_name, gender, birth_date,
    }) => {
      const check = {
        name,
        // eslint-disable-next-line camelcase
        family_name,
        gender,
        // eslint-disable-next-line camelcase
        birth_date,
        ...isNew ? {} : { id: data.id },
      };
      // eslint-disable-next-line camelcase
      if (name && family_name && gender && birth_date) {
        const r = await api.get('/api/cat/peoples/check_name/', auth, check);
        if (r.ok) {
          const d = await r.json();
          setNameChecked(true);
          setNamePassed(d.count === 0);
        }
      } else {
        setNameChecked(false);
      }
    },
    [auth, data.id, isNew],
  );
  const debouncedCheckPhone = useMemo(
    () => debounce(checkPhone, 1000),
    [checkPhone],
  );
  const debouncedCheckEMail = useMemo(
    () => debounce(checkEMail, 1000),
    [checkEMail],
  );
  const debouncedCheckName = useMemo(
    () => debounce(checkName, 1000),
    [checkName],
  );

  const getType = useCallback(
    (d) => {
      if (!d) return '';
      if (typeof d === 'string') {
        const sts = d.split('.');
        if (sts.length) return sts[sts.length - 1];
        return '';
      }
      const re = /^data:([a-z,A-Z,0-9,/]+);base64,.*/;
      const groups = re.exec(d.file);
      if (!groups) return '';

      switch (groups[1]) {
        case 'application/pdf':
          return 'pdf';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'docx';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'xlsx';
        case 'image/jpeg':
          return 'jpg';
        default:
          // eslint-disable-next-line no-console
          console.debug(`Mime type for ${d.file} unsupported`);
          return '';
      }
    },
    [],
  );

  const getURL = useCallback(
    (d) => {
      if (!d) return '';
      if (typeof d === 'string') return d;
      return d.file;
    },
    [],
  );

  const { cvType, cvURL } = useMemo(
    () => ({
      cvType: getType(data.cv),
      cvURL: getURL(data.cv),
    }),
    [getType, data.cv, getURL],
  );

  const { clType, clURL } = useMemo(
    () => ({
      clType: getType(data.cover_letter),
      clURL: getURL(data.cover_letter),
    }),
    [getType, data.cover_letter, getURL],
  );

  const photoURL = useMemo(
    () => getURL(data.photo),
    [data.photo, getURL],
  );
  const uploadCV = useCallback(
    (e, file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const f = reader.result;

        const value = {
          file: f,
          filename: file.name,
        };
        onChange({ cv: value });
        api.put('/api/cat/peoples/parse_cv/', auth, {
          ...value,
          ...isNew ? {} : { id: data.id },
        }).then((r) => {
          if (r.ok) {
            r.json().then((d) => {
              const reqs = ['name', 'family_name', 'gender', 'birth_date', 'email', 'phone'];
              onChange(
                reqs
                  .filter((k) => !!d[k])
                  .reduce((R, k) => ({ ...R, [k]: d[k] }), {}),
              );
              setPhoneChecked(false);
              setEmailChecked(false);
              setNameChecked(false);
              if (d.name && d.family_name && d.gender && d.birth_date) {
                checkName(d.name, d.family_name, d.gender, d.birth_date);
              }
              if (d.email) {
                checkEMail(d.email);
              }
              if (d.phone) {
                checkPhone(d.phone);
              }
            });
          }
        });
      };
      reader.readAsDataURL(file);
    },
    [auth, checkEMail, checkName, checkPhone, data.id, isNew, onChange],
  );

  const uploadPhoto = useCallback(
    (e, file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const f = reader.result;
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(file);
        /// /
        const newPhoto = {
          file: f,
          fileURL: a.href,
          filename: file.name,
          type: file.type,
        };
        onChange({ photo: newPhoto });
      };
      reader.readAsDataURL(file);
    },
    [onChange],
  );

  const uploadCoverLetter = useCallback(
    (e, file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const f = reader.result;
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(file);
        const newCoverLetter = {
          file: f,
          fileURL: a.href,
          filename: file.name,
          type: file.type,
        };

        onChange({ cover_letter: newCoverLetter });
      };
      reader.readAsDataURL(file);
    },
    [onChange],
  );

  const onNameOtherChange = useCallback(
    (e, v) => {
      onChange(v);
      setNameChecked(false);
      debouncedCheckName({
        name: data.name,
        family_name: data.family_name,
        gender: data.gender,
        birth_date: data.birth_date,
        ...v,
      });
    },
    [data.birth_date, data.family_name, data.gender, data.name, debouncedCheckName, onChange],
  );

  const onEmailChange = useCallback(
    (e, v) => {
      onChange({ email: v });
      setEmailChecked(false);
      debouncedCheckEMail(v);
    },
    [debouncedCheckEMail, onChange],
  );

  const onPhoneChange = useCallback(
    (e, v) => {
      onChange({ phone: v });
      setPhoneChecked(false);
      debouncedCheckPhone(v);
    },
    [debouncedCheckPhone, onChange],
  );

  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, onChange, readOnly);
  const familyNameProps = editorHooks.useStringInputProps('family_name', data, fields, fieldErrors, onChange, readOnly);
  const genderProps = editorHooks.useSelectorInputProps('gender', data, fields, fieldErrors, onChange, readOnly);
  const birthDateProps = editorHooks.useDateInputProps('birth_date', data, fields, fieldErrors, onChange, readOnly);
  const phoneProps = editorHooks.useStringInputProps('phone', data, fields, fieldErrors, onChange, readOnly);
  const skypeProps = editorHooks.useStringInputProps('skype', data, fields, fieldErrors, onChange, readOnly);
  const emailProps = editorHooks.useStringInputProps('email', data, fields, fieldErrors, onChange, readOnly);
  const viberWhatsappProps = editorHooks.useStringInputProps('viber_whatsapp', data, fields, fieldErrors, onChange, readOnly);
  const positionsProps = editorHooks.useSelectorInputProps('positions', data, fields, fieldErrors, onChange, readOnly);
  const maritalProps = editorHooks.useSelectorInputProps('marital_status', data, fields, fieldErrors, onChange, readOnly);
  const citizenProps = editorHooks.useSelectorInputProps('citizenship', data, fields, fieldErrors, onChange, readOnly);
  const citizen2Props = editorHooks.useSelectorInputProps('citizenship2', data, fields, fieldErrors, onChange, readOnly);
  const citizenEUProps = editorHooks.useSelectorInputProps('citizen_eu', data, fields, fieldErrors, onChange, readOnly);
  const postalCodeProps = editorHooks.useStringInputProps('postal_code', data, fields, fieldErrors, onChange, readOnly);
  const cityProps = editorHooks.useStringInputProps('city', data, fields, fieldErrors, onChange, readOnly);
  const countryProps = editorHooks.useSelectorInputProps('country', data, fields, fieldErrors, onChange, readOnly);
  const addrProps = editorHooks.useStringInputProps('address', data, fields, fieldErrors, onChange, readOnly);
  const notesProps = editorHooks.useStringInputProps('notes', data, fields, fieldErrors, onChange, readOnly);
  const authorProps = editorHooks.useSelectorInputProps('author_id', data, fields, fieldErrors, onChange, readOnly);

  const nameOK = nameChecked && namePassed;
  const nameErrors = useMemo(
    () => {
      const newErrors = [
        ...(nameProps.errors || []),
        ...((nameChecked && !namePassed) ? ['This candidate already exists in our database'] : []),
      ];
      if (!newErrors.length) return null;
      return newErrors;
    },
    [nameChecked, namePassed, nameProps.errors],
  );
  const emailOK = emailChecked && emailPassed;
  const emailErrors = useMemo(
    () => {
      const newErrors = [
        ...(emailProps.errors || []),
        ...((emailChecked && !emailPassed) ? ['This candidate already exists in our database'] : []),
      ];
      if (!newErrors.length) return null;
      return newErrors;
    },
    [emailChecked, emailPassed, emailProps.errors],
  );
  const phoneOK = phoneChecked && phonePassed;
  const phoneErrors = useMemo(
    () => {
      const newErrors = [
        ...(phoneProps.errors || []),
        ...((phoneChecked && !phonePassed) ? ['This candidate already exists in our database'] : []),
      ];
      if (!newErrors.length) return null;
      return newErrors;
    },
    [phoneChecked, phonePassed, phoneProps.errors],
  );

  const [GDPRAgree, setGDPRAgree] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [GDPRShow, setGDPRShow] = useState(false);
  const [GDPRText, setGDPRText] = useState(null);
  const canSave = !readOnly && GDPRAgree;
  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/templates/gdpr/', auth);

      if (r.status === 404) return null;
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    if (!currentUser) {
      loader()
        .then((d) => setGDPRText(d.template))
        .catch(() => setGDPRText(null));
    } else {
      setGDPRAgree(true);
    }
  }, [auth, currentUser]);
  const GDPRVisible = !!GDPRText && !currentUser;
  const canChangeAuthor = currentUser && currentUser.profile && currentUser.profile.can_change_author;

  return (
    <div>
      {currentUser && (
        <Nav className="gap-2 mx-3 mt-3">
          <Button variant="primary" onClick={onSave} disabled={!canSave}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Save
          </Button>
          <DeleteButton content="Delete" onClick={onDelete} disabled={readOnly} />
        </Nav>
      )}
      <Container fluid>
        <Row className="gy-4">
          <Col xl={6}>
            <FileUpload onFileUpload={uploadCV} errors={fieldErrors.cv} className="h-100">
              <Form.Label className="text-white" aria-required={fields.cv && fields.cv.required}>
                DROP Your CV
              </Form.Label>

              {cvType ? (
                <FilePreview
                  filePath={cvURL}
                  fileType={cvType}
                />
              ) : (
                <div className="text-warning small">
                  Recommended format: pdf. Supported: jpg, jpeg, gif, png, bmp,docx, xlsx files.
                </div>
              )}
            </FileUpload>
          </Col>
          <Col xl={6} className="pt-2">
            <h5 className="mt-2">
              <FontAwesomeIcon icon={faIdCard} className="me-2" />
              General info
            </h5>
            <Row className="border rounded bg-white py-2">
              {data.id && (
                <div className="display-6 border-bottom">
                  <FontAwesomeIcon icon={faBarcode} className="me-1 text-muted" size="sm" title="PIN number" />
                  <span className="fw-bold text-primary">{data.id}</span>
                </div>
              )}
              <Col xxl={4} xl={6}>
                <FileUpload
                  onFileUpload={uploadPhoto}
                  errors={fieldErrors.photo}
                  minHeight
                >
                  <Form.Label className="text-white" aria-required={fields.photo.required}>
                    DROP Your Photo
                  </Form.Label>
                  {!data.photo && (
                    <div className="invalid-feedback">
                      Recommended format: jpg. Supported: jpg, jpeg, gif, png, bmp files.
                    </div>
                  )}
                  {/* {photoURL && ( */}
                  <ImgFoto src={photoURL} alt="" />
                  {/* )} */}
                </FileUpload>
              </Col>
              <Col xxl={8} xl={6}>
                <Row>
                  <Col xl={6}>
                    <EditorControls.StringInput
                      {...nameProps}
                      onChange={(e, v) => onNameOtherChange(e, { name: v })}
                      append={nameOK && (
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </InputGroup.Text>
                      )}
                      errors={nameErrors}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.StringInput
                      {...familyNameProps}
                      onChange={(e, v) => onNameOtherChange(e, { family_name: v })}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.RadioInput
                      className="d-flex gap-2"
                      {...genderProps}
                      onChange={(e, v) => onNameOtherChange(e, { gender: v })}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.DateInput
                      {...birthDateProps}
                      onChange={(e, v) => onNameOtherChange(e, { birth_date: v })}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.StringInput
                      {...phoneProps}
                      onChange={(e, v) => onPhoneChange(e, v)}
                      append={phoneOK && (
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </InputGroup.Text>
                      )}
                      errors={phoneErrors}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.StringInput {...skypeProps} />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.StringInput
                      {...emailProps}
                      onChange={(e, v) => onEmailChange(e, v)}
                      append={emailOK && (
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </InputGroup.Text>
                      )}
                      errors={emailErrors}
                    />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.StringInput {...viberWhatsappProps} />
                  </Col>
                </Row>
              </Col>
              <Col xl={12}>
                <Row>
                  <Col xl={6}>
                    <EditorControls.MultiSelector {...positionsProps} values={positions.selectorValues} />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.RadioInput {...maritalProps} className="d-flex flex-column flex-sm-row gap-2 flex-wrap" />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.SelectorInput {...citizenProps} values={countries.selectorValues} />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.SelectorInput {...citizen2Props} values={countries.selectorValues} />
                  </Col>
                  <Col xl={6}>
                    <EditorControls.RadioInput {...citizenEUProps} className="d-flex gap-2" />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faLanguage} className="me-2" />
                Languages
              </h5>
              <Languages
                onChange={onChange}
                languages={languages}
                data={data}
                errors={fieldErrors}
                fields={fields}
              />
            </div>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faShip} className="me-2" />
                Applying for
              </h5>
              <Preferences
                onChange={onChange}
                companies={companies}
                data={data}
                errors={fieldErrors}
                fields={fields}
              />
            </div>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                Address
              </h5>
              <Row className="border rounded bg-white py-2" xl={2}>
                <Col md={4}>
                  <EditorControls.StringInput {...postalCodeProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.StringInput {...cityProps} />
                </Col>
                <Col md={4}>
                  <EditorControls.SelectorInput {...countryProps} values={countries.selectorValues} />
                </Col>
                <Col>
                  <EditorControls.StringInput {...addrProps} />
                </Col>
              </Row>
            </div>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faAddressBook} className="me-2" />
                Additional contacts
              </h5>
              <AddContacts
                onChange={onChange}
                contactInfoTypes={contactInfoTypes}
                data={data}
                errors={fieldErrors}
                fields={fields}
              />
            </div>
          </Col>
          <Col xl={6}>
            <FileUpload
              className="h-100"
              onFileUpload={uploadCoverLetter}
              errors={fieldErrors.cover_letter}
            >
              <ContentLabel text="DROP Your Reference Letter" required={fields.cover_letter.required} />
              {clType ? (
                <FilePreview
                  filePath={clURL}
                  fileType={clType}
                />
              ) : (
                <div className="text-info small">
                  Recommended format: pdf. Supported: jpg, jpeg, gif, png, bmp,docx, xlsx files.
                </div>
              )}
            </FileUpload>

          </Col>
          <Col xl={6}>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faBriefcase} className="me-2" />
                Work experience
              </h5>
              <WorkExpiriences
                onChange={onChange}
                positions={positions}
                data={data}
                errors={fieldErrors}
                fields={fields}
              />
            </div>
            <div className="pb-2">
              <h5 className="mt-2">
                <FontAwesomeIcon icon={faUserGraduate} className="me-2" />
                Education
              </h5>
              <Educations
                onChange={onChange}
                data={data}
                errors={fieldErrors}
                fields={fields}
              />
            </div>
            <h5 className="mt-2">
              <FontAwesomeIcon icon={faMessage} className="me-2" />
              Notes
            </h5>
            <EditorControls.TextInput {...notesProps} label="" />
            {canChangeAuthor && (
              <>
                <h5 className="mt-2">
                  <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                  Change recruiter
                </h5>
                <EditorControls.SelectorInput {...authorProps} label="Recruteur" values={users.selectorValues} />
              </>
            )}
          </Col>
        </Row>
        {!currentUser && (
          <Row className="mt-3">
            <Col className="d-flex justify-content-end align-items-end">
              {GDPRVisible && (
              <EditorControls.CheckboxInput
                value={GDPRAgree}
                onChange={(e, v) => setGDPRAgree(v)}
                label={(
                  <div>
                    I agree with
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      className="px-2"
                      onClick={(e) => {
                        setGDPRShow(true);
                        e.preventDefault();
                      }}
                    >
                      GDPR Consent Statement
                    </a>
                  </div>
                )}
              />
              )}
            </Col>
            <Col>
              <Nav className="gap-2 mx-3 mt-3">
                <Button variant="primary" onClick={onSave} disabled={!canSave}>
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  Save
                </Button>
              </Nav>
            </Col>
          </Row>
        )}
      </Container>
      <Modal show={GDPRShow} onHide={() => setGDPRShow(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>GDPR Consent Statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: GDPRText }} />
        </Modal.Body>
      </Modal>
    </div>

  );
}

PeoplesEditor.propTypes = {
  data: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}),
  fieldErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  changed: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

PeoplesEditor.defaultProps = {
  fields: {},
  fieldErrors: {},
  readOnly: false,
};

export default PeoplesEditor;
