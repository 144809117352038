import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

/**
 *
 * @param data {{}}
 * @param onChange {function}
 * @param tableName {string}
 * @param errors {{}}
 * @param readOnlyFields {string[]}
 * @param fields {{}}
 * @param nameCol {{}}
 *
 * @returns {{
 *    tableData: {}[],
 *    activeRow: number,
 *    activeCol: string,
 *    tableContainerRef: {current},
 *    highlights: string[],
 *    showFooter: bool,
 *    pinHeader: bool,
 *    tableReadOnlyFields: string[],
 *    tableErrors: {},
 *    tableFields: {},
 *    columns: [{}],
 *    tableActions: {
 *          onAddRow(function(@param defaults {{}}): void),
 *          onCopyRow(function(@param rowIndex {number}): void),
 *          onDeleteRow(function(@param rowIndex {number}): void),
 *          onMoveUpRow(function(@param rowIndex {number}): void),
 *          onMoveDownRow(function(@param rowIndex {number}): void),
 *          onCellChange(function(@param rowIndex {number}, @param partData {{}}): void),
 *          onHighlightColumn(function(@param columns {''[], ''}): void),
 *          onUnhighlightColumn(function(@param columns {''[], ''}): void),
 *          onToggleHighlightColumn(function(@param columns {''[], ''}): void),
 *          onToggleFooter(function(): void),
 *          onTogglePin(function(): void),
 *    },
 * }}

*/
const useTablePart = ({
  data, onChange, tableName, errors, readOnlyFields, fields,
}) => {
  const [active, setActive] = useState({ row: null, col: null });
  const [highlights, setHighlights] = useState([]); // ПОдсвеченные колонки (массив имен)
  const [showFooter, setShowFooter] = useState(true);
  const [pinHeader, setPinHeader] = useState(true);
  const tableContainerRef = useRef();

  const tableData = useMemo(
    () => (data && tableName in data ? data[tableName] : []),
    [data, tableName],
  );
  const tableFields = useMemo(
    () => (fields && tableName in fields ? fields[tableName].child.children : {}),
    [fields, tableName],
  );
  const rowsCount = tableData.length;

  const tableReadOnlyFields = useMemo(
    () => (readOnlyFields && tableName in readOnlyFields ? readOnlyFields[tableName] : []),
    [readOnlyFields, tableName],
  );
  const tableErrors = useMemo(
    () => (errors && tableName in errors ? errors[tableName] : []),
    [errors, tableName],
  );

  const onAddRow = useCallback(
    async (defaults = {}) => {
      await onChange((oldData) => ({
        [tableName]: [...oldData[tableName], defaults],
      }));
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onCopyRow = useCallback(
    async (rowIndex) => {
      await onChange(
        (oldData) => ({
          [tableName]: [...oldData[tableName], oldData[tableName][rowIndex]],
        }),
      );
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onDeleteRow = useCallback(
    async (rowIndex) => {
      await onChange((oldData) => ({
        [tableName]: [
          ...oldData[tableName].slice(0, rowIndex),
          ...oldData[tableName].slice(rowIndex + 1),
        ],
      }));
      // eslint-disable-next-line no-confusing-arrow
      setActive((o) => o.row === rowsCount - 1 ? { row: rowsCount - 1, col: o.col } : o);
    },
    [onChange, rowsCount, tableName],
  );
  const activeColName = active.col;
  const onMoveUpRow = useCallback(
    async (rowIndex) => {
      if (rowIndex) {
        return onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex - 1),
            oldData[tableName][rowIndex],
            oldData[tableName][rowIndex - 1],
            ...oldData[tableName].slice(rowIndex + 1),
          ],
        }))
          .then(() => setActive({ row: rowIndex - 1, col: activeColName }));
      }
      return onChange((oldData) => ({
        [tableName]: [...oldData[tableName].slice(1), oldData[tableName][0]],
      }));
    },
    [onChange, tableName, activeColName],
  );

  const onMoveDownRow = useCallback(
    async (rowIndex) => {
      if (rowIndex < rowsCount - 1) {
        return onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex),
            oldData[tableName][rowIndex + 1],
            oldData[tableName][rowIndex],
            ...oldData[tableName].slice(rowIndex + 2),
          ],
        })).then(() => setActive({ row: rowIndex + 1, col: activeColName }));
      }
      return onChange((oldData) => ({
        [tableName]: [
          oldData[tableName][rowIndex],
          ...oldData[tableName].slice(0, rowIndex),
        ],
      }));
    },
    [rowsCount, onChange, tableName, activeColName],
  );

  const onMoveRow = useCallback(
    async (from, to) => {
      if (from < to) {
        return onChange((oldData) => ({
          [tableName]: [
            ...oldData[tableName].slice(0, from),
            ...oldData[tableName].slice(from + 1, to),
            { ...oldData[tableName][to], isActive: true },
            { ...oldData[tableName][from], isActive: false },
            ...oldData[tableName].slice(to + 1),
          ],
        })).then(() => setActive({ row: to, col: activeColName }));
      }

      return onChange((oldData) => ({
        [tableName]: [
          ...oldData[tableName].slice(0, to),
          { ...oldData[tableName][from], isActive: false },
          { ...oldData[tableName][to], isActive: true },
          ...oldData[tableName].slice(to + 1, from),
          ...oldData[tableName].slice(from + 1),
        ],
      })).then(() => setActive({ row: to, col: activeColName }));
    },
    [onChange, tableName, activeColName],
  );

  const onCellChange = useCallback(
    async (e, rowIndex, partData) => onChange((oldData) => ({
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        { ...oldData[tableName][rowIndex], ...partData },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [onChange, tableName],
  );

  const onHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [...new Set([...o, ...c])]);
    },
    [],
  );
  const onUnhighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => o.filter((cc) => !c.includes(cc)));
    },
    [],
  );
  const onToggleHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [
        ...o.filter((cc) => !c.includes(cc)),
        ...c.filter((cc) => !o.includes(cc)),
      ]);
    },
    [],
  );

  const onToggleFooter = useCallback(
    () => setShowFooter((o) => !o),
    [],
  );
  const onTogglePin = useCallback(
    () => setPinHeader((o) => !o),
    [],
  );

  const tableActions = useMemo(
    () => ({
      onAddRow,
      onCopyRow,
      onDeleteRow,
      onMoveUpRow,
      onMoveDownRow,
      onMoveRow,
      onCellChange,
      onHighlightColumn,
      onUnhighlightColumn,
      onToggleHighlightColumn,
      onToggleFooter,
      onTogglePin,
    }),
    [onAddRow, onCellChange, onCopyRow, onMoveRow,
      onDeleteRow, onHighlightColumn,
      onMoveDownRow, onMoveUpRow,
      onToggleFooter, onToggleHighlightColumn, onTogglePin, onUnhighlightColumn],
  );

  const currActiveRow = useRef(null);

  useEffect(
    () => {
      currActiveRow.current = active.row;
    },
    [active.row],
  );

  return {
    activeRow: active.row,
    activeCol: active.col,
    showFooter,
    pinHeader,
    tableActions,
    tableContainerRef,
    highlights,
    tableData,
    tableReadOnlyFields,
    tableErrors,
    tableFields,
  };
};

export default useTablePart;
